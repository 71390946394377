<template>
    <div class="videoList_page Between flex-wrap">
        <div class="videoList pointer" v-for="(item,index) in videoList" :key="index" @click="selectBtn(item)">
            <video :poster="item.coverUrl" src=""></video>
            <img class="icon_open" src="@/assets/img/open.png" alt="">
        </div>
        <div class="video_mask_layer" v-if="videoShow == true">
            <div class="mask">
            </div>
            <div class="video_cont">
                <img class="offBtn pointer" @click="videoShow = false" src="@/assets/img/off.png" alt="">
                <video class="videoCont" :src="videoUrl" width="100%" controls></video>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        videoList:{}
    },
    data(){
        return{
            videoShow:false,
            videoUrl:""
        }
    },
    methods:{
        selectBtn(item){
            this.videoShow = true;
            this.videoUrl = item.videoUrl
        }
    }
}
</script>
<style lang="scss" scoped>

    .videoList_page{
        .videoList{
            width: 575px;
            height: 350px;
            position: relative;
            margin-bottom: 45px;
            video{
                width: 575px;
                height: 350px;
            }
            .icon_open{
                width: 48px;
                height: 48px;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -24px;
                margin-left: -24px;
            }
        }
    }
        .video_mask_layer {
            .video_cont {
                width: 900px;
                height: 550px;
                background: white;
                position: fixed;
                top: 50%;
                left: 50%;
                margin-top: -275px;
                margin-left: -450px;
                z-index: 10;
                padding: 20px;
                box-sizing: border-box;
    
                .videoCont {
                    width: 100%;
                    height: 100%;
                }
    
                .offBtn {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
</style>