<template>
    <div class="newsList_page">
        <div class="newsInfo flex flex-wrap">
            <div class="news_list pointer" v-for="(item,index) in newsList" :key="index" @click="toDetails(item)">
                <div style="overflow:hidden;">
                    <img class="icon_news" :src="item.newsImgUrl" alt="" />
                </div>
                <div class="info">
                    <div class="date_info  flex-center">
                        <img class="icon_time icon_items" src="@/assets/img/homes/time_y.png" alt="">
                        <img class="icon_time" src="@/assets/img/homes/time_n.png" alt="">
                        <div class="date">{{ item.createTime }}</div>
                    </div>
                    <div class="details astrict">{{ item.newsTitle }}</div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    props:{
        newsList:{},
        fatherId:{},
    },
    data(){
        return{}
    },
    methods:{
        toDetails(item){
             this.$router.push({
                     path:"/news/newsDetails",
                     query:{
                        ids:6,
                         id:item.id,
                         name: item.newsTitle,
                         fatherId: this.fatherId
                     }
                 })
            //  let hrefUrl = window.location.href
            //  if (hrefUrl.indexOf("/index") >= 0){
            //     let routeData = this.$router.resolve({
            //     path:"/news/newsDetails",   
            //     query: {
            //         ids:6,
            //         id:item.id,
            //         name: item.newsTitle,
            //         fatherId: this.fatherId,
            //         isReturn:1
            //     }
            // });
            // window.open(routeData.href, '_blank');
            //  }else{
            //      this.$router.push({
            //          path:"/news/newsDetails",
            //          query:{
            //             ids:6,
            //              id:item.id,
            //              name: item.newsTitle,
            //              fatherId: this.fatherId
            //          }
            //      })

            //  }
        }
    }
}
</script>
<style lang="scss" scoped>


    .newsList_page{
        .newsInfo {
                margin-top: 50px;
        
                .news_list:nth-child(3n+1) {
                    margin-left: 0px !important
                }
        
                .news_list:hover {
                    border-bottom: 2px solid #e62129;
                    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
        
                    .icon_news {
                        transform: scale(1.1);
                        -webkit-transform: scale(1.1);
                    }
        
                    .info::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 0;
                        height: 3px;
                        background: #e62129;
        
                    }
        
                    .icon_time {
                        display: none !important;
                    }
        
                    .icon_items {
                        display: block !important;
                    }
        
                    .date {
                        color: #e62129 !important;
                    }
        
                    .details {
                        color: #e62129 !important;
                    }
                }
        
                .news_list {
                    width: 31%;
                    margin-left: 40px;
                    overflow: hidden;
                    box-sizing: border-box;
                    margin-bottom:  28px ;
                    border-bottom: 2px solid #fff;
        
                    .icon_news {
                        width: 100%;
                        height: 262px;
                        transition: All 0.8s ease;
                        vertical-align: middle;
                    }
        
                    .info {
                        transition: All 0.5s ease;
                        -webkit-transition: All 0.5s ease;
                        background: #fff;
                        padding: 35px 30px 30px;
        
                        .date_info {
                            .icon_time {
                                width: 16px;
                                height: 16px;
                                display: block;
                            }
        
                            .icon_items {
                                display: none;
                            }
        
                            .date {
                                padding-left: 16px;
                                font-size: 16px;
                                color: #ccc;
                            }
                        }
        
                        .details {
                            font-size: 18px;
                            color: #666;
                            padding-top: 15px;
                            height: 72px;
                            line-height: 30px;
                            overflow: hidden;
                        }
                    }
                }
            }
    }
</style>